import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "update", 
                     "invoice_address", 
                     "total",
                     "form",
                     "shipping_cost"]

  quantity_updated() {
  	this.formTarget.noValidate = "1"
		this.updateTarget.click()  
  }

  toggle_invoice_address() {
  	this.invoice_addressTarget.classList.toggle("hide")
  }

  prefil() {
  }

  total() {
    this.totalTarget.textValue
  }

  update_total(total) {
    this.totalTarget.innerHTML = total
  }  

  update_shipping_cost(shipping_cost) {
    this.shipping_costTarget.innerHTML = shipping_cost
  }  

}
