import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "invoice_address"]

  toggle_invoice_address() {
    this.invoice_addressTarget.classList.toggle("hide")
  }

}
