import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["postcode",
                    "postcode_error_message",
                    "house_number",
                    "house_number_error_message",
                    "street",
                    "city",
                    "country"]

  lookup() {
    if (this.countryTarget.value == "1") {
      this.get_address_details(this.postcodeTarget.value,
                               this.house_numberTarget.value, 
                               this)
    } else {
  	  this.get_be_city(this.postcodeTarget.value,
                      this)
    }
  }

  get_address_details(postcode, number, callback) {
    Rails.ajax({
      type: "GET", 
      url: "https://api.pro6pp.nl/v1/autocomplete",
      data: "auth_key=Gv6RIcAvP9b4jhAu&domain=" + 
            window.location.hostname + 
            "&nl_sixpp=" + postcode + 
            "&streetnumber=" + number,
      success: function(data){ 
        if (data.status == "ok") {
          var address_details = data.results[0]
          callback.updateAddress(address_details)
        } else if (data.status == "error") {
          console.log("Error")
          callback.displayErrorMessage(data.error.message)
        }
      }
    })
  }

  get_be_city(postcode, callback) {
    Rails.ajax({
      type: "GET", 
      url: "https://api.pro6pp.nl/v1/autocomplete",
      data: "auth_key=Gv6RIcAvP9b4jhAu&domain=" + 
            window.location.hostname + 
            "&be_fourpp=" + postcode,
      success: function(data){ 
        if (data.status == "ok") {
          callback.update_be_city(data.results[0]['municipality_nl'])
        } else if (data.status == "error") {
          console.log("Error")
          callback.displayErrorMessage(data.error.message)
        }
      }
    })
  }

  update_be_city(city) {
    this.clearErrorMessages()

    this.cityTarget.value = city
    this.cityTarget.setCustomValidity('')    
  }

  updateAddress(address_details) {
    this.clearErrorMessages()

    this.streetTarget.value = address_details.street
    this.streetTarget.setCustomValidity('')
    this.cityTarget.value = address_details.city
    this.cityTarget.setCustomValidity('')    
  }

  displayErrorMessage(message) {
    console.log("displayErrorMessage1")
    this.clearErrorMessages()
    console.log("displayErrorMessage2")

    if (message.toLowerCase().includes("streetnumber") || 
        message.includes("extension")) {
      console.log("Ongeldig huisnummer")
      this.setErrorMessage(this.house_numberTarget, "Ongeldig huisnummer")
    } else {
      console.log("Ongeldige postcode")
      this.setErrorMessage(this.postcodeTarget, "Ongeldige postcode")
    }
    console.log("Klaaf")

  }

  clearErrorMessages() {

    this.postcodeTarget.classList.remove("is-invalid")
    this.house_numberTarget.classList.remove("is-invalid")
  }

  setErrorMessage(input_element, message) {
    input_element.classList.add("is-invalid")

    var elements = input_element.parentElement.getElementsByClassName("invalid-feedback")
    var error_message
    if (elements.length == 0) {
      error_message = document.createElement('div')
      error_message.setAttribute('class', 'invalid-feedback')
      input_element.parentElement.appendChild(error_message)
    } else {
      error_message = elements[0]        
    }
    error_message.innerHTML = message
  }

}
