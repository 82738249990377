import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["submit"]

  submitForm() {
    event.preventDefault()
    this.submitTarget.value = event.target.dataset.destination
    this.submitTarget.click()    
  }    

}
