import { Controller } from "stimulus"

export default class extends Controller {

  update_count_and_go_to_shopping_cart(event) {
    let [data, status, xhr] = event.detail;

    this.navigationController.update_cart_count(JSON.parse(xhr.response)["cart_count"])
    this.navigationController.go_to_shopping_cart()
  }

  get navigationController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("navigation"), "navigation")
  }  

}
