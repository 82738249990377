import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["quantity", "total", "submit", "delete"]

  update() {
    let [data, status, xhr] = event.detail;
    if (JSON.parse(xhr.response)["status"] == "OK") {
      this.quantityTarget.value = JSON.parse(xhr.response)["quantity"];
      this.totalTarget.textContent = JSON.parse(xhr.response)["order_line_total"];
      this.navigationController.update_cart_count(JSON.parse(xhr.response)["cart_count"])
      this.shoppingcartController.update_total(JSON.parse(xhr.response)["shopping_cart_total"])
      this.shoppingcartController.update_shipping_cost(JSON.parse(xhr.response)["shipping_cost"])
    } else if (JSON.parse(xhr.response)["status"] == "DELETE") {
      this.deleteTarget.click()
    }
  }

  submitForm() {
    this.submitTarget.click()    
  }  

  get shoppingcartController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("shoppingcart"), "shoppingcart")
  }  

  get navigationController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("navigation"), "navigation")
  }  

}
