import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["completed","submit","completed_at","tableRow"]

  update() {
    this.submitTarget.click()  
  }

  done() {
    let [data, status, xhr] = event.detail;
    this.completed_atTarget.innerHTML = xhr.response;
  }

  done_and_hide() {
    let [data, status, xhr] = event.detail;
    this.tableRowTarget.classList.toggle("fadeout");

  }

}
