import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cart_count", "shopping_cart_link"]

  update_cart_count(cart_count) {
    this.cart_countTarget.textContent = cart_count;
  }

  go_to_shopping_cart() {
    this.shopping_cart_linkTarget.click()
  }

}
